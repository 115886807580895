import React, {Component} from 'react'

class UpdateAvailableModal extends Component {
  render() {
    return (
      <div/>
    );
  }
}

export default UpdateAvailableModal;
