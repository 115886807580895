import React, {Component} from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import {Route, Switch, withRouter} from 'react-router'
import PropTypes from 'prop-types';

import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions'

import './App.css';
import UpdateAvailableModal from "./common/UpdateAvailableModal";

class App extends Component {

  constructor(props) {
    super(props);

    this.state = {
      updateAvailable: false,
    };
  }

  componentDidMount() {
    if (process.env.NODE_ENV === 'production') {
      if (!!this.props.update) {
        this.props.update.then(() => this.setState({updateAvailable: true})).catch();
      } else {
        console.warn('Received null promise for app update');
      }
    }
  }

  render() {
    return (
      <Router>
        <Switch>
          <Route path='/'>
            <Router>
              <ScrollRestorer>
                <Switch>
                </Switch>
                {this.state.updateAvailable && <UpdateAvailableModal/>}
              </ScrollRestorer>
            </Router>
          </Route>
        </Switch>
      </Router>

    );
  }
}

App.propTypes = {
  update: PropTypes.instanceOf(Promise)
};

class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0)
    }
  }

  render() {
    return this.props.children
  }
}

const ScrollRestorer = withRouter(ScrollToTop);

export default App;
